<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card>
            <div slot="header">
              <CRow>
                <CCol sm="6">
                    <strong>Encargos</strong>
                  </CCol>
                <CCol sm="6" style="text-align:right;">
                    <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                </CCol>
                </CRow>
          </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th colspan="3">
                    <h2>{{ p.enc_name }}</h2>
                </th>
                <th colspan="3">
                    <h2 style="text-align: right; color: darkgreen;">{{ encargo_total | grana }} %</h2>
                </th>
            </tr>
            <tr>
                <th></th>
                <th>Grupo</th>
                <th>Nome</th>
                <th>Perc.</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(encargoItem, index) in enc" :key="index">
                    <td>
                      <b-form-checkbox @change.native="editar(index)"
                        v-model="enc[index].eit_active" 
                      ></b-form-checkbox>
                      <a @click="removeEsse(index)">❌</a>
                    </td>
                    <td>
                      <b-form-input @blur.native="editar(index)" v-model="enc[index].eit_group"></b-form-input>
                    </td>
                    <td>
                      <b-form-input @blur.native="editar(index)" v-model="enc[index].eit_name"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric :options="perc" class="form-control" @blur.native="editar(index)" v-model="enc[index].eit_valor"></vue-autonumeric>
                    </td>
                    <td>
                      
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                      <b-form-input v-model="eitGroup"></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="eitName"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric :options="perc" class="form-control" v-model="eitValor"></vue-autonumeric>
                    </td>
                    <td><button type="button" class="btn btn-success" @click="inserir()">Adicionar</button></td>
                </tr>
            </tbody>
        </table>
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Encargos Trabalhistas">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/hFnGvoKxIu8?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
var vm
export default {
  data () {
    return {
      optionsEncargos: [],
      optionsEncargosList: [],
      produtoItem: [],
      encargoItem: [],
      sessao: '',
      loading: false,
      enc: [],
      p: [],
      eitName: '',
      eitGroup: '',
      eitValor: 0,
      eitActive: 1,
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      perc: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      novoEncargo: '',
      encargo: 1,
      encargo_total: 0,
      encargo_total_grupo: [],
      encdiv: [],
      enc_total: [],
      enc_total_parcial: []
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    // this.produto = this.$route.params.id
    this.encargo = 1
    this.verificaSessao()
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    }
  },
  methods: {
    verificaSessao: function () {
      if(isNaN(this.encargo) || this.encargo === 0) {
        this.$notify({
          type: 'danger',
          title: 'Falha ao encontrar produto',
          text: 'Inválido ou não encontrado'
        })
        return false
      }
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    removeEsse: function (i) {
      this.enc[index].eit_valor_mes = 0
      this.enc[index].eit_active = 0
      this.remover('pro_Encargo_Item', 'eit_valor_mes = ' + this.enc[index].eit_ID, '').then(
        (a) => {
          this.recarregar()
        }
      )
    },
    recarregar: function () {
      this.loading = true
      this.remover('pro_Encargo_Item', 'eit_valor_mes = 0', '')
      this.listar('pro_Encargo_Item', 'eit_group, eit_name', 'enc_ID = ' + this.encargo + '').then(
        (rtmp) => {
            this.enc = rtmp
            this.encargo_total = 0
            for (var i = 0; i < rtmp.length; i++) {
                if (rtmp[i].eit_active) {
                  this.encargo_total += rtmp[i].eit_valor
                  if (!this.encargo_total_grupo[rtmp[i].eit_group]) { this.encargo_total_grupo[rtmp[i].eit_group] = 0 }
                  this.encargo_total_grupo[rtmp[i].eit_group] += rtmp[i].eit_valor
                }
            }
            this.listar('pro_Encargo', 'enc_name', 'enc_ID = ' + this.encargo).then(
                (r) => {
                    this.p = r[0]
                    this.atualizar('pro_Encargo', {resource: [ { enc_total : this.encargo_total } ]}, 'enc_ID = ' + this.encargo, '', '1').then(
                        (r2) => {},
                        (r2) => {}
                    )
                    this.loading = false
                },
                (r) => {
                    this.$notify({
                        type: 'danger',
                        title: 'Dados insuficientes',
                        text: 'Preencha as tabelas anteriores antes de acessar esta área.'
                    })
                    this.loading = false
                }
            )
        },
        (rtmp) => {
            this.$notify({
                type: 'danger',
                title: 'Dados insuficientes',
                text: 'Preencha as tabelas anteriores antes de acessar esta área.'
            })
            this.loading = false
        }
      )
    },
    inserir: function () {
      this.loading = true
      // Verifica se já existe
      this.campo('pro_Encargo_Item', 'eit_ID', '(eit_name = ' + this.eitName + ') AND (eit_group = ' + this.eitGroup + ')').then(
        (response) => {
          // Se existe, avisa
          this.$swal('Esse item já existe')
          this.loading = false
          return false
        }, 
        (response) => {
          var elem = {
            enc_ID: this.encargo, 
            eit_group: this.eitGroup,
            eit_name: this.eitName,
            eit_valor: this.eitValor, 
            eit_active: 1,
            grp_ID: this.$session.get('grupo')
          }
          this.criar(elem)
        }
      )
    },
    criar: function (el) {
      this.$http.post(this.URLApi + 'prolucro/_table/pro_Encargo_Item', {resource: [ el ]}, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then(
        (response) => {
          this.enc = []
          this.recarregar()
        },
        (response) => {
          this.$swal('Falha ao inserir')
        }
      )
    },
    editar: function (index) {
      this.loading = true
      this.atualizar('pro_Encargo_Item', {resource: [ {enc_ID: this.encargo, eit_valor: this.enc[index].eit_valor, 
      eit_active: this.enc[index].eit_active, enc_name: this.enc[index].eit_name, enc_group: this.enc[index].eit_group} ]}, 'eit_ID = ' + this.enc[index].eit_ID).then(
        (response) => {
          this.recarregar()
        },
        (response) => {
          this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          this.loading = false
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>